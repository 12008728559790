import React, {useContext, useState} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {getTranslation} from "../utils/helper";
import {AppContext} from "../appContext";
import PhoneInput from "react-phone-input-2";

const ModalContactUs = ({title, message, showModal, setShowModal}) => {
    //#region Get language from context, manage languages
    const {dictionary, defaultDictionary} = useContext(AppContext);
    //#endregion

    const [clientInfo, setClientInfo] = useState({
        name: '',
        phoneNumber: '',
    });

    const handlerForm = (e) => {
        let message = `${clientInfo.name} 👤 | ${clientInfo.phoneNumber} ☎️`
        let botApi = '5643993490:AAH2pyZmHgcGq5f_BYGEYzJRY5CVgCXkvxc';
        let user_id = '-646764504';

        e.preventDefault();
        let api = new XMLHttpRequest();
        api.open("GET", `https://api.telegram.org/bot${botApi}/sendMessage?chat_id=${user_id}&text=${message}`, true)
        api.send();
        setClientInfo({
            name: '',
            phoneNumber: '',
        })

        setShowModal(false);
    };

    const updateClient = (fieldName, newValue) => {
        console.log(newValue);
        setClientInfo(state => ({
            ...state,
            [fieldName]: newValue,
        }));
    }

    return (
        <>
            <Modal show={showModal} fullscreen='true' onHide={() => {
                setShowModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title> {title || getTranslation('modalContactUsTitle', dictionary, defaultDictionary)} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-wrapper">

                        <form onSubmit={(e) => handlerForm(e)} className={'FlexColumns'}>
                            <label htmlFor="itemName">
                                {getTranslation('modalContactUsIntroduceYourselfText', dictionary, defaultDictionary)}
                            </label>
                            <input
                                required
                                value={clientInfo.name}
                                type="text"
                                name="email"
                                id="itemName"
                                // placeholder={getTranslation('makeOrderName', dictionary, defaultDictionary)}
                                onChange={(event) => updateClient('name', event.target.value)}
                            />

                            <label htmlFor="itemPhone">
                                {getTranslation('modalContactUsPhoneNumberText', dictionary, defaultDictionary)}
                            </label>
                            <PhoneInput
                                required={true}
                                name={'PhoneNumbers'}
                                country={'cz'}
                                id={'itemPhone'}
                                onChange={(event) => updateClient('phoneNumber', event)}
                                //onBlur={(event) => changeInputValueHandler(event.target.value, 'PhoneNumbers')}
                                specialLabel={''}
                            />
                            <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                                {getTranslation('modalContactUsLeaveRequestButton', dictionary, defaultDictionary)}
                            </button>
                        </form>
                        <h6 className={'PromiseToCall text-center BlueText'}>
                            {getTranslation('modalContactUsWeWillCallYouText', dictionary, defaultDictionary)}
                        </h6>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalContactUs;
