import React, {useContext, useRef} from "react";
import {AppContext} from "../../appContext";
import {getTranslation} from "../../utils/helper";

const ContactOne = ({isDetailing = false}) => {
  //#region Get language from context, manage languages
  const {dictionary, defaultDictionary} = useContext(AppContext);
  //#endregion

  const carModelRef = useRef();
  const nameUserRef = useRef();
  const numberUserRef = useRef();
  const addInfoRef = useRef();

  const formInput = [
    {id: 'item01', type: 'text', placeholder: `Ваше ім'я`, ref: nameUserRef},
    {
      id: 'item02',
      type: 'text',
      placeholder: `Номер телефону`,
      ref: numberUserRef
    },
    {id: 'item03', type: 'text', placeholder: `Нік Instagram`, ref: addInfoRef},
  ];

  const handlerForm = (e) => {
    let message = `${nameUserRef?.current?.value} 👤 | ${numberUserRef?.current?.value} ☎️ | ${addInfoRef?.current?.value} 📩 | ${carModelRef?.current?.value} 🗒`
    let botApi = '5643993490:AAH2pyZmHgcGq5f_BYGEYzJRY5CVgCXkvxc';
    let user_id = '-646764504';

    e.preventDefault();
    let api = new XMLHttpRequest();
    api.open("GET", `https://api.telegram.org/bot${botApi}/sendMessage?chat_id=${user_id}&text=${message}`, true)
    api.send();
    nameUserRef.current.value = '';
    numberUserRef.current.value = '';
    addInfoRef.current.value = '';
    carModelRef.current.value = '';
  }

  //#region Render
  return (
    <>
      <div className="contact-form--1" id={'makeOrder'}>
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                {/*<h2 className="title">{getTranslation('makeOrderTitle', dictionary, defaultDictionary)}</h2>*/}
                <p
                  className="">{isDetailing ? getTranslation('detailingContactFormTitle', dictionary, defaultDictionary) :
                  getTranslation('makeOrderDescription', dictionary, defaultDictionary)}
                  {/*<a href="mailto:carnice@email.cz"> carnice@email.cz</a> */}
                </p>
              </div>
              <div className="form-wrapper">

                <form onSubmit={(e) => handlerForm(e)}>
                  <label htmlFor="item01">
                    <input
                      ref={carModelRef}
                      type="text"
                      name="name"
                      id="item01"
                      placeholder={isDetailing ?
                        getTranslation('detailingContactFormYourCar', dictionary, defaultDictionary)
                        :
                        getTranslation('makeOrderWishedCar', dictionary, defaultDictionary)}
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      ref={nameUserRef}
                      type="text"
                      name="email"
                      id="item02"
                      placeholder={getTranslation('makeOrderName', dictionary, defaultDictionary)}
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      ref={numberUserRef}
                      type="text"
                      name="subject"
                      id="item03"
                      placeholder={getTranslation('makeOrderPhone', dictionary, defaultDictionary)}
                    />
                  </label>

                  <label htmlFor="item04">
                    <textarea
                      ref={addInfoRef}
                      type="text"
                      id="item04"
                      name="message"
                      placeholder={getTranslation('makeOrderAddInfo', dictionary, defaultDictionary)}
                    />
                  </label>
                  <button className="rn-button-style--2 btn-solid" type="submit"
                          value="submit" name="submit"
                          id="mc-embedded-subscribe">
                    {getTranslation('makeOrderButtonText', dictionary, defaultDictionary)}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div
                className={
                  isDetailing ? 'Image2Container' : 'Image2Container' +
                    ' Image2Container_Landing'
                }
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //#endregion
}
export default ContactOne;
