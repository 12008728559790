import React, {useContext, useState}  from 'react';
import {FiCheck} from "react-icons/fi";
import {getTranslation} from "../utils/helper";
import {AppContext} from "../appContext";
import {Col, Row} from "react-bootstrap";
import ModalContactUs from "../modals/ModalContactUs";

const PricingBody = ({textData}) => {
    //#region Get language from context, manage languages
    const {dictionary, defaultDictionary} = useContext(AppContext);
    //#endregion

    //#region Handle visibility
    const [isBodyVisible, setIsBodyVisible] = useState(false);
    //#endregion

    //#region Contact modal displaying
    const [connectModal, setConnectModal] = useState(false);

    const handleOrderClick = (event) => {
        event.preventDefault();

        setIsBodyVisible(false);
        setConnectModal(true);
    };
    //#endregion

    //#region Render
    return (
        <React.Fragment>
            <div className="pricing-body">
                <Row>
                    <Col md={6} xs={6}>
                        <button className={'rn-btn'} onClick={() => setIsBodyVisible(state => !state)}>
                            {!isBodyVisible ? getTranslation('pricingBodyShowDetails', dictionary, defaultDictionary)
                                : getTranslation('pricingBodyHideDetails', dictionary, defaultDictionary)}
                        </button>
                    </Col>
                    <Col md={6} xs={6}>
                        <a
                            className="rn-btn"
                            href="#"
                            onClick={(event) => handleOrderClick(event)}
                        >
                            {getTranslation('pricingTableCardPurchaseNow', dictionary, defaultDictionary)}
                        </a>
                    </Col>
                </Row>

                {isBodyVisible &&
                    <ul className="list-style--1 mt--20">
                        {textData.map((text, index) =>
                            <React.Fragment key={index}>
                                <li>
                                    <FiCheck />
                                    {getTranslation(text, dictionary, defaultDictionary)}
                                </li>
                            </React.Fragment>
                        )}
                    </ul>
                }
            </div>

            {connectModal &&
                <ModalContactUs
                    title={getTranslation('aboutModalContactTitle', dictionary, defaultDictionary)}
                    message={''}
                    showModal={connectModal}
                    setShowModal={setConnectModal}
                />
            }
        </React.Fragment>
    );
    //#endregion
};

export default PricingBody;
