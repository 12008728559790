import React, {useContext, useState} from 'react';
import {AppContext} from "../appContext";
import ModalContactUs from "../modals/ModalContactUs";

const Services = ({ServiceList, title}) => {
  //#region Get language from context, manage languages
  const {dictionary, defaultDictionary} = useContext(AppContext);
  //#endregion

  const [connectModal, setConnectModal] = useState(false);

  //#region Render
  return (
    <>
      {/*<div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">*/}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb--10">
              <h2 className="title">
                {title}
              </h2>
              {/*<p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
            </div>
          </div>
        </div>
        <div className="row creative-service">
          {ServiceList.map((val, i) => (
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb--20"
              key={i}
            >
              <a className="text-center" href="#" onClick={(event) => {
                event.preventDefault()
              }}>
                <div className="service service__style--2 h-100">
                  <div className="icon">
                    {val.icon}
                  </div>
                  <div className="content">
                    {/*<h3 className="title">{val.title}</h3>*/}
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      {/*</div>*/}

      {connectModal &&
        <ModalContactUs
          showModal={connectModal}
          setShowModal={setConnectModal}
        />
      }
    </>
  );
  //#endregion
};

export default Services;
