import React, {Component, Fragment, useContext, useState} from "react";
import ContactOne from "../elements/contact/ContactOne";
import {getTranslation} from "../utils/helper";
import {AppContext} from "../appContext";
import Services from "../elements/Services";
import About from "../elements/About";
import PricingTable from "../blocks/PricingTable";
import ServiceTwo from "../elements/service/ServiceTwo";
import ModalContactUs from "../modals/ModalContactUs";
import {
  FaCar,
  FaCarBattery,
  FaCarCrash,
  FaOilCan,
  GiCarWheel,
  GiGears, GiReceiveMoney, GiSandsOfTime, GiSmokeBomb,
  GiValve,
  GoTools, IoIosSchool,
  IoIosSpeedometer
} from "react-icons/all";
import {GiCarSeat} from "@react-icons/all-files/gi/GiCarSeat";
import {GiCarDoor} from "@react-icons/all-files/gi/GiCarDoor";

const LandingPage = () => {
  //#region Get language from context, manage languages
  const {dictionary, defaultDictionary} = useContext(AppContext);
  //#endregion

  //#region Contact us modal
  const [connectModal, setConnectModal] = useState(false);
  //#endregion

  const SlideList = [
    {
      textPosition: 'text-center',
      category: getTranslation('aboutUsPretitle', dictionary, defaultDictionary),
      title: `<span class='BlueText'>
                        ${getTranslation('aboutUsTitleFirstPart', dictionary, defaultDictionary)}
                    </span>
                    <span>${getTranslation('aboutUsTitleSecondPart', dictionary, defaultDictionary)}</span>
                    ${getTranslation('aboutUsTitleThirdPart', dictionary, defaultDictionary)}`,
      description: getTranslation('aboutUsDescription', dictionary, defaultDictionary),
      buttonText: getTranslation('aboutUsButtonText', dictionary, defaultDictionary),
      buttonLink: 'https://t.me/+420721030003'
    }
  ];

  const ServiceList = [
    {
      icon: <FaCar/>,
      // title: getTranslation('servicesWhatWeCheckTenthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckTenthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiCarSeat/>,
      // title: getTranslation('servicesWhatWeCheckEighthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckEighthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiValve/>,
      // icon: <TbEngine />,
      // title: getTranslation('servicesWhatWeCheckSeventhTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckSeventhTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiCarDoor/>,
      // title: getTranslation('servicesWhatWeCheckFourthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckFourthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiGears/>,
      // title: getTranslation('servicesWhatWeCheckNinthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckNinthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <FaOilCan/>,
      // title: getTranslation('servicesWhatWeCheckThirdTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckThirdTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GoTools/>,
      // title: getTranslation('servicesWhatWeCheckFifthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckFifthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <FaCarCrash/>,
      // title: getTranslation('servicesWhatWeCheckSixthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckSixthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiCarWheel/>,
      // title: getTranslation('servicesWhatWeCheckTwelfthTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckTwelfthTitle', dictionary, defaultDictionary),
    },
    {
      icon: <IoIosSpeedometer/>,
      // title: getTranslation('servicesWhatWeCheckFirstTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckFirstTitle', dictionary, defaultDictionary),
    },
    {
      icon: <FaCarBattery/>,
      // title: getTranslation('servicesWhatWeCheckEleventhTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckEleventhTitle', dictionary, defaultDictionary),
    },
    {
      icon: <GiSmokeBomb/>,
      // title: getTranslation('servicesWhatWeCheckSecondTitle', dictionary, defaultDictionary),
      description: getTranslation('servicesWhatWeCheckSecondTitle', dictionary, defaultDictionary),
    },
  ];

  const ServiceListTwo = [
    {
      // icon: <FaCar />,
      // title: getTranslation('serviceTwoTitle', dictionary, defaultDictionary),
      description: getTranslation('serviceTwoTitle', dictionary, defaultDictionary)
    },
    {
      icon: <FaCar/>,
      // title: 'Business Stratagy',
      description: getTranslation('serviceTwoWatchedSomeVariants', dictionary, defaultDictionary)
    },
    {
      icon: <IoIosSchool/>,
      // title: 'Website Development',
      description: getTranslation('serviceTwoDontHaveSkills', dictionary, defaultDictionary)
    },
    {
      icon: <GiSandsOfTime/>,
      // title: 'Marketing & Reporting',
      description: getTranslation('serviceTwoDontHaveTime', dictionary, defaultDictionary)
    },
    {
      icon: <FaCarCrash/>,
      // title: 'Mobile App Development',
      description: getTranslation('serviceTwoAfraidOfScum', dictionary, defaultDictionary)
    },
    {
      icon: <GiReceiveMoney/>,
      // title: 'Mobile App Development',
      description: getTranslation('serviceTwoWantCheaperDeal', dictionary, defaultDictionary)
    }
  ];

  //#region Render
  return (
    <>
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16"
            key={index}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {/*{value.category ? <span className={'ThemeText'}>{value.category}</span> : ''}*/}
                    {value.title ?
                      <h1 className="MainTitle BlueText"
                          dangerouslySetInnerHTML={{__html: value.title}}></h1>
                      :
                      ''}
                    {value.description ? <p
                      className="description WhiteText mt--50">{value.description}</p> : ''}
                    {value.buttonText ?
                      <div className="slide-btn">
                        <a className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`} target={'_blank'}>
                          {value.buttonText}
                        </a>
                      </div> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="about-area about-position-top ptb--40">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-12 CarImage">
                <div
                  className={'ImageContainer ImageContainer_Landing'}
                >

                </div>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="button"
                  name="ordercarcheck"
                  id="ordercarcheck"
                  onClick={() => setConnectModal(true)}
                >
                  {getTranslation('servicesOrderCarCheckButton', dictionary, defaultDictionary)}
                </button>
              </div>

              <div className="col-lg-7 col-sm-12" id={'whatIsChecking'}>
                <div className="about-inner inner">
                  <Services
                    ServiceList={ServiceList}
                    title={getTranslation('aboutUsTitle', dictionary, defaultDictionary)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-area pb--20">
        <ContactOne isDetailing={false}/>
      </div>

      <About/>

      <PricingTable/>

      <ServiceTwo ServiceList={ServiceListTwo}/>

      <div className="about-area mb--30">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="thumbnail">
                  <img className="w-100 mh-100"
                       src="/assets/images/about/about-7.jpg" alt="Car"/>
                </div>
              </div>

              <div className="col-lg-5 BlueText text-left" id={'whyNeedUs'}>
                <p>
                  {getTranslation('convinceNeedOurServiceFirstParagraph', dictionary, defaultDictionary)}
                </p>
                <p>
                  {getTranslation('convinceNeedOurServiceSecondParagraph', dictionary, defaultDictionary)}
                </p>
                <p>
                  {getTranslation('convinceNeedOurServiceThirdParagraph', dictionary, defaultDictionary)}
                  <a
                    href={'https://www.instagram.com/carnice.cz/'}
                    target={'_blank'}
                  >
                    {' CarNice.cz'}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<OurWork />*/}

      {connectModal &&
        <ModalContactUs
          showModal={connectModal}
          setShowModal={setConnectModal}
        />
      }
    </>
  );
  //#endregion
}

export default LandingPage;
