import React, { useContext, useState } from "react";
import {AppContext} from "../../appContext";
import {getTranslation} from "../../utils/helper";
import ModalContactUs from "../../modals/ModalContactUs";
import {FaCar, FaCarCrash, GiReceiveMoney, GiSandsOfTime, IoIosSchool} from "react-icons/all";

const ServiceTwo = ({ServiceList = []}) => {
    //#region Get language from context, manage languages
    const {dictionary, defaultDictionary} = useContext(AppContext);
    //#endregion

    const [connectModal, setConnectModal] = useState(false);

    //#region Render
    return(
            <React.Fragment>
                <div className={'container'}>
                    <div className="row mb--30">
                        <div className="col-lg-12 col-12 mt_md--50">
                            <div className="row service-one-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb--20" key={i}>
                                        <a href="#" onClick={(event) => event.preventDefault()}>
                                            <div className="service service__style--2 RiscDescription h-100">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p className={'BlueText '}>
                                                        {val.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {connectModal &&
                    <ModalContactUs
                        title={getTranslation('aboutModalContactTitle', dictionary, defaultDictionary)}
                        message={''}
                        showModal={connectModal}
                        setShowModal={setConnectModal}
                    />
                }
            </React.Fragment>
        );
    //#endregion
}
export default ServiceTwo;
