import React, {useContext, useEffect, useState} from 'react';
import {getTranslation} from "../utils/helper";
import {AppContext} from "../appContext";
import Services from "../elements/Services";
import ContactOne from "../elements/contact/ContactOne";
import PricingTable from "../blocks/PricingTable";
import ModalContactUs from "../modals/ModalContactUs";
import {
  FaBaby,
  FaCar,
  FaCarCrash,
  GiCarWheel,
  GiCrystalShine,
  GiDustCloud,
  GiMoneyStack,
  GiReceiveMoney,
  GiScarWound,
  GiSpiderWeb,
  ImSad2,
  MdLocalCarWash,
} from "react-icons/all";
import {GiCarSeat} from "@react-icons/all-files/gi/GiCarSeat";
import {GiCarDoor} from "@react-icons/all-files/gi/GiCarDoor";
import ServiceTwo from "../elements/service/ServiceTwo";
import {IoShield} from "@react-icons/all-files/io5/IoShield";

const DetailingPage = ({
                         addClasses = '', setIsMainPage = () => {
  }
                       }) => {
  //#region Get language from context, manage languages
  const {dictionary, defaultDictionary} = useContext(AppContext);
  //#endregion

  //#region Contact us modal
  const [connectModal, setConnectModal] = useState(false);
  //#endregion

  useEffect(() => {
    setIsMainPage(false);
  }, []);

  const SlideList = [{
    textPosition: 'text-center',
    category: getTranslation('detailingPretitle', dictionary, defaultDictionary),
    title: `<span class='BlueText'>
        ${getTranslation('detailingTitle', dictionary, defaultDictionary)}
      </span>`,
    // description: getTranslation('detailingDescription', dictionary, defaultDictionary),
    buttonText: getTranslation('detailingButtonText', dictionary, defaultDictionary),
    buttonLink: 'https://t.me/+420721030003'
  }];

  const ServiceList = [{
    icon: <GiCrystalShine/>, // icon: <TbEngine />,
    // title: getTranslation('servicesWhatWeCheckSeventhTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesThirdTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiCarSeat/>, // title: getTranslation('servicesWhatWeCheckEleventhTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesEleventhTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <IoShield/>, // title: getTranslation('servicesWhatWeCheckFourthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesFourthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiReceiveMoney/>, // title: getTranslation('servicesWhatWeCheckSecondTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesTwelfthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiCarSeat/>, // title: getTranslation('servicesWhatWeCheckFifthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesSeventhTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiScarWound/>, // title: getTranslation('servicesWhatWeCheckSixthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesEighthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiDustCloud/>, // title: getTranslation('servicesWhatWeCheckThirdTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesSixthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <MdLocalCarWash/>, // title: getTranslation('servicesWhatWeCheckFirstTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesTenthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiCarDoor/>, // title: getTranslation('servicesWhatWeCheckTwelfthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesNinthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <GiCarWheel/>, // title: getTranslation('servicesWhatWeCheckNinthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesFifthTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <FaCar/>, // title: getTranslation('servicesWhatWeCheckTenthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesFirstTitle', dictionary, defaultDictionary),
  }, {
    icon:
      <FaCarCrash/>, // title: getTranslation('servicesWhatWeCheckEighthTitle', dictionary, defaultDictionary),
    description: getTranslation('detailingServicesSecondTitle', dictionary, defaultDictionary),
  },];

  const ServiceListTwo = [{
    description: getTranslation('Услуги детейлинга, химчистки и полировки автомобиля актуальны в случаях', dictionary, defaultDictionary)
  }, {
    icon: <FaBaby/>,
    description: getTranslation('Вы передвигаетесь в машине с детьми или перевозите домашних животных, поэтому имеются загрязнения', dictionary, defaultDictionary)
  }, {
    icon: <ImSad2/>,
    description: getTranslation('Вы устали от того, что после дождя и снега стекла грязные, загрязнения плохо отмываются от машины', dictionary, defaultDictionary)
  }, {
    icon: <GiSpiderWeb/>,
    description: getTranslation('Ваш кузов имеет царапины, потерял прежний блеск, видна «паутинка» на солнце', dictionary, defaultDictionary)
  }, {
    icon: <GiMoneyStack/>,
    description: getTranslation('Хотите продать автомобиль выгодно, но переживаете, что покупателей будут смущать дефекты кузова и грязный салон', dictionary, defaultDictionary)
  }, {
    icon: <IoShield/>,
    description: getTranslation('Хотите защитить свой кузов от внешнего воздействия: солнца, воды, птиц, камней', dictionary, defaultDictionary)
  },];

  //#region Render
  return (
    <React.Fragment>
      <div className="slider-wrapper detailing">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (<div
          className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16"
          key={index}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className={`inner ${value.textPosition}`}>
                  {value.title ? <h1 className="MainTitle BlueText"
                    dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                  {value.description ? <p
                    className="description WhiteText mt--50">{value.description}</p> : ''}
                  {value.buttonText ? <div className="slide-btn">
                    <a className="rn-button-style--2 btn-primary-color"
                      href={`${value.buttonLink}`} target={'_blank'}>
                      {value.buttonText}
                    </a>
                  </div> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>))}
      </div>

      <div className="about-area about-position-top ptb--40">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-12 CarImage">
                {/*<div className="thumbnail">*/}
                {/*<img className="" src="/assets/images/about/about-5.png" alt="Car"/>*/}
                <div className={'ImageContainer'}>

                </div>
                {/*<button*/}
                {/*    className="rn-button-style--2 btn-solid"*/}
                {/*    type="button"*/}
                {/*    name="ordercarcheck"*/}
                {/*    id="ordercarcheck"*/}
                {/*    onClick={() => setConnectModal(true)}*/}
                {/*>*/}
                {/*    {getTranslation('servicesOrderCarCheckButton', dictionary, defaultDictionary)}*/}
                {/*</button>*/}
                {/*</div>*/}
              </div>

              <div className="col-lg-7 col-sm-12" id={'whatIsChecking'}>
                <div className="about-inner inner">
                  {/*<div className="row mt--10">*/}
                  <Services
                    ServiceList={ServiceList}
                    title={getTranslation('detailingServicesChapterTitle', dictionary, defaultDictionary)}
                  />
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-area pb--20">
        <ContactOne isDetailing={true}/>
      </div>

      <PricingTable isDetailing={true}/>

      <ServiceTwo ServiceList={ServiceListTwo}/>

      {connectModal && <ModalContactUs
        showModal={connectModal}
        setShowModal={setConnectModal}
      />}
    </React.Fragment>);
  //#endregion
};

export default DetailingPage;
