import React, {useContext, useEffect, useState} from 'react'
import {getTranslation} from "../utils/helper";
import {AppContext} from "../appContext";
import PricingBody from "../elements/PricingBody";

const PricingTable = ({isDetailing = false}) => {
    //#region Get language from context, manage languages
    const {lang, dictionary, defaultDictionary} = useContext(AppContext);
    //#endregion

    //#region Pricing data
    const [pricingData, setPricingData] = useState([]);

    useEffect(() => {
        if (isDetailing) {
            setPricingData([
                {
                    title: getTranslation('Комплекс-лайт химчистка', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 3000,
                    textData: [
                        'Наружная мойка автомобиля',
                        'Чистка сидений и обшивок дверей при помощи профессиональной химии и оборудования',
                        'Влажная уборка пластика',
                        'Уборка ковролина + локальная химчистка',
                        'Сухая уборка потолка',
                        'Уборка багажника и ниши запасного колеса + локальная химчистка',
                        'Протирка стекол и зеркал',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Комплекс-детейлинг химчистка', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 5000,
                    textData: [
                        'Наружная мойка автомобиля с чисткой резины и дисков',
                        'Очистка дверных проемов и петель',
                        'Чистка сидений и обшивок дверей при помощи профессиональной химии и оборудования',
                        'Глубокая чистка пластика с последующей консервацией',
                        'Глубокая чистка ковролина со снятием сидений',
                        'Чистка потолка',
                        'Чистка багажника и ниши запасного колеса, мойка запасного колеса',
                        'Мойка окон и зеркал',
                        'Уход за кожей',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Локальная чистка интерьера', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 300,
                    textData: [
                        'Одно сидение (500 Kč)',
                        'Задние сидения (1000 Kč)',
                        'Комплект сидений -2 передних + задние (1500 Kč)',
                        'Обшивка дверей (250 Kč)',
                        'Мойка дверных проемов и петель (300 Kč)',
                        'Чистка багажника (500 Kč)',
                        'Мойка окон и зеркал (500 Kč)',
                        'Чистка потолка (1500 Kč)',
                        'Чистка ковролина со снятием сидений (1500 Kč)',
                        'Чистка пластика с консервацией (1500 Kč)',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Локальная чистка интерьера', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 5000,
                    textData: [
                        'Одношаговая полировка',
                        'Двухфазная мойка кузова автомобиля',
                        'Удаление битумных пятен на кузове',
                        'Удаление металлических вкраплений и очистка с помощью глины',
                        'Полировка с помощью одношаговой двухкомпонентной пасты',
                        'Удаление остатков пасты, мойка и сушка кузова автомобиля',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Двухшаговая полировка', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 7000,
                    textData: [
                        'Двухфазная мойка автомобиля',
                        'Удаление битумных пятен на кузове',
                        'Удаление металлических вкраплений и очистка с помощью глины',
                        'Полировка с помощью пасты высокой или средней абразивности',
                        'Полировка с помощью финишной пасты',
                        'Удаление остатков пасты, мойка и сушка кузова автомобиля',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Восстановление лака', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 10000,
                    textData: [
                        'Двухфазная мойка автомобиля',
                        'Удаление битумных пятен на кузове',
                        'Удаление металлических вкраплений и очистка с помощью глины',
                        'Шлифование глубоких царапин при помощи абразива',
                        'Полировка с помощью пасты высокой и средней абразивности',
                        'Полировка с помощью финишной пасты',
                        'Удаление остатков пасты, мойка и сушка кузова автомобиля',
                        ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('Защитные покрытия и дополнительные услуги', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 500,
                    textData: [
                        'Нанесение высококачественного керамического покрытия (от 5000 Kč)',
                        'Нанесение воскового покрытия (от 2500 Kč)',
                        'Гидрофобное кварцевое покрытие (от 1000 Kč)',
                        'Покрытие стекол "антидождь" (от 500 Kč)',
                        'Очистка и чернение резины (от 500 Kč)',
                        'Подкраска сколов и царапин (от 1000 Kč)',
                        'Полировка фары (500 Kč/шт)',
                        'Восстановление покрытия фары + защита (1500 Kč/шт)',
                    ],
                    measurement: 'Kč',
                },
            ]);
        } else {
            setPricingData([
                {
                    title: getTranslation('pricingTableCardTitleOutDiagnostic', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 1500,
                    textData: [
                        'pricingTableCardFullBodyCheck',
                        'pricingTableCardUnderHoodCheck',
                        'pricingTableCardElectronicSystemCheck',
                        'pricingTableCardMileageCheck',
                        'pricingTableCardTextDrive',
                        'pricingTableCardInvestmentsEvaluation',
                        'pricingTableCardPurchaseAdvice',
                        'pricingTableCardBargain',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('pricingTableCardTitleServiceStationDiagnostic', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 800,
                    textData: [
                        'pricingTableCardTitleServiceStationDiagnostic',
                        'pricingTableCardChassisDiagnostic',
                        'pricingTableCardRemovingAggregatesDefence',
                        'pricingTableCardCylindersEndoscopy',
                        'pricingTableCardCompressionMeasurement',
                        'pricingTableCardInvestmentsEvaluation',
                        'pricingTableCardPurchaseAdvice',
                        'pricingTableCardBargain',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('pricingTableCardTitleSelectionFull', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 15000,
                    textData: [
                        'pricingTableCardRecommendations',
                        'pricingTableCardUnlimitedCheck',
                        'pricingTableCardComplexCheck',
                        'pricingTableCardBargain',
                        'pricingTableCardDealProcessing',
                        'pricingTableGuarantee',
                        'pricingTableYouGiveTechTask',
                    ],
                    measurement: 'Kč',
                },
                {
                    title: getTranslation('pricingTableCardTitleSpecialistForDay', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 5000,
                    textData: [
                        'pricingTableCardCarsAgreement',
                        'pricingTableCardCarsCheckDuringDay',
                        'pricingTableCardChoiceOfCarForTrip',
                        'pricingTableCardUnlimitedCheckLocation',
                        'pricingTableCardUnlimitedChecksNumber',
                        'pricingTableCardBargain',
                    ],
                    measurement: 'Kč'
                },
                {
                    title: getTranslation('pricingTableCardTitleCarDelivery', dictionary, defaultDictionary),
                    priceFromTitle: getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary),
                    price: 15,
                    textData: [
                        'pricingTableCardDealProcessing',
                        'pricingTableCardCarDrivingByProfessional',
                        'pricingTableCardCarTesting',
                        'pricingTableCardDriverResponsibility',
                        'pricingTableCardAllExpendituresIncluded',
                        'pricingTableCardServicesArea',
                    ],
                    measurement: 'Kč/km'
                },
            ]);
        }
    }, [lang]);
    //#endregion

    //#region Render
    return (
        <>
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--40">
                    <h2 className={'title'} id={'prices'}>
                        {getTranslation('detailingPricingTableTitle', dictionary, defaultDictionary)}
                    </h2>
                    <div className="container">
                        <div className="row">
                            {pricingData.map((data, index) =>
                                <React.Fragment key={index}>
                                    <div className="col-lg-4 col-md-6 col-12 mb--20 PricingCard">
                                        <div className="rn-pricing h-100">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-header">
                                                    <h4 className="title">
                                                        {data.title}
                                                    </h4>
                                                    <div className="pricing">
                                                        <span className="price PriceTitle">{`${data.priceFromTitle} ${data.price}`}</span>
                                                        <span className="subtitle">{data.measurement}</span>
                                                    </div>
                                                </div>
                                                <PricingBody
                                                    textData={data.textData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {/*#region Old code */}
                            {/*<div className="col-lg-4 col-md-4 col-12 mb--20 PricingCard">*/}
                            {/*    <div className="rn-pricing h-100">*/}
                            {/*        <div className="pricing-table-inner">*/}
                            {/*            <div className="pricing-header">*/}
                            {/*                <h4 className="title">*/}
                            {/*                    {getTranslation('pricingTableCardTitleOutDiagnostic', dictionary, defaultDictionary)}*/}
                            {/*                </h4>*/}
                            {/*                <div className="pricing">*/}
                            {/*                    <span className="price PriceTitle">{getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary)} 1200</span>*/}
                            {/*                    <span className="subtitle">Kč</span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*                <PricingBody*/}
                            {/*                    textData={[*/}
                            {/*                        'pricingTableCardFullBodyCheck',*/}
                            {/*                        'pricingTableCardUnderHoodCheck',*/}
                            {/*                        'pricingTableCardElectronicSystemCheck',*/}
                            {/*                        'pricingTableCardMileageCheck',*/}
                            {/*                        'pricingTableCardTextDrive',*/}
                            {/*                        'pricingTableCardInvestmentsEvaluation',*/}
                            {/*                        'pricingTableCardPurchaseAdvice',*/}
                            {/*                        'pricingTableCardBargain',*/}
                            {/*                    ]}*/}
                            {/*                />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-4 col-12 mb--20 PricingCard">*/}
                            {/*    <div className="rn-pricing h-100">*/}
                            {/*        <div className="pricing-table-inner">*/}
                            {/*            <div className="pricing-header">*/}
                            {/*                <h4 className="title">*/}
                            {/*                    {getTranslation('pricingTableCardTitleServiceStationDiagnostic', dictionary, defaultDictionary)}*/}
                            {/*                </h4>*/}
                            {/*                <div className="pricing">*/}
                            {/*                    <span className="price PriceTitle">{getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary)} 800</span>*/}
                            {/*                    <span className="subtitle">Kč</span>*/}
                            {/*                </div>*/}
                            {/*           </div>*/}
                            {/*<PricingBody*/}
                            {/*                textData={[*/}
                            {/*                    'pricingTableCardTitleServiceStationDiagnostic',*/}
                            {/*                    'pricingTableCardChassisDiagnostic',*/}
                            {/*                    'pricingTableCardRemovingAggregatesDefence',*/}
                            {/*                    'pricingTableCardCylindersEndoscopy',*/}
                            {/*                    'pricingTableCardCompressionMeasurement',*/}
                            {/*                    'pricingTableCardInvestmentsEvaluation',*/}
                            {/*                    'pricingTableCardPurchaseAdvice',*/}
                            {/*                    'pricingTableCardBargain',*/}
                            {/*                ]}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-4 col-12 mb--20 PricingCard">*/}
                            {/*    <div className="rn-pricing h-100">*/}
                            {/*        <div className="pricing-table-inner">*/}
                            {/*            <div className="pricing-header">*/}
                            {/*                <h4 className="title">*/}
                            {/*                    {getTranslation('pricingTableCardTitleSelectionFull', dictionary, defaultDictionary)}*/}
                            {/*                </h4>*/}
                            {/*                <div className="pricing">*/}
                            {/*                    <span className="price PriceTitle">{getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary)} 15000</span>*/}
                            {/*                    <span className="subtitle">Kč</span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <PricingBody*/}
                            {/*                textData={[*/}
                            {/*                    'pricingTableCardRecommendations',*/}
                            {/*                    'pricingTableCardUnlimitedCheck',*/}
                            {/*                    'pricingTableCardComplexCheck',*/}
                            {/*                    'pricingTableCardBargain',*/}
                            {/*                    'pricingTableCardDealProcessing',*/}
                            {/*                    'pricingTableGuarantee',*/}
                            {/*                    'pricingTableYouGiveTechTask',*/}
                            {/*                ]}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-4 col-12 mb--20 PricingCard">*/}
                            {/*    <div className="rn-pricing h-100">*/}
                            {/*        <div className="pricing-table-inner">*/}
                            {/*            <div className="pricing-header">*/}
                            {/*                <h4 className="title">*/}
                            {/*                    {getTranslation('pricingTableCardTitleSpecialistForDay', dictionary, defaultDictionary)}*/}
                            {/*                </h4>*/}
                            {/*                <div className="pricing">*/}
                            {/*                    <span className="price PriceTitle">{getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary)} 5000</span>*/}
                            {/*                    <span className="subtitle">Kč</span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <PricingBody*/}
                            {/*                textData={[*/}
                            {/*                    'pricingTableCardCarsAgreement',*/}
                            {/*                    'pricingTableCardCarsCheckDuringDay',*/}
                            {/*                    'pricingTableCardChoiceOfCarForTrip',*/}
                            {/*                    'pricingTableCardUnlimitedCheckLocation',*/}
                            {/*                    'pricingTableCardUnlimitedChecksNumber',*/}
                            {/*                    'pricingTableCardBargain',*/}
                            {/*                ]}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-4 col-12 mb--20 PricingCard">*/}
                            {/*    <div className="rn-pricing h-100">*/}
                            {/*        <div className="pricing-table-inner">*/}
                            {/*            <div className="pricing-header">*/}
                            {/*                <h4 className="title">*/}
                            {/*                    {getTranslation('pricingTableCardTitleCarDelivery', dictionary, defaultDictionary)}*/}
                            {/*                </h4>*/}
                            {/*                <div className="pricing">*/}
                            {/*                    <span className="price PriceTitle">{getTranslation('pricingTableCardPriceFrom', dictionary, defaultDictionary)} 15</span>*/}
                            {/*                    <span className="subtitle">Kč/km</span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <PricingBody*/}
                            {/*                textData={[*/}
                            {/*                    'pricingTableCardDealProcessing',*/}
                            {/*                    'pricingTableCardCarDrivingByProfessional',*/}
                            {/*                    'pricingTableCardCarTesting',*/}
                            {/*                    'pricingTableCardDriverResponsibility',*/}
                            {/*                    'pricingTableCardAllExpendituresIncluded',*/}
                            {/*                    'pricingTableCardServicesArea',*/}
                            {/*                ]}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*#endregion*/}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}
        </>
    );
    //#endregion
}
export default PricingTable;
