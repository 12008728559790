/***********************************************************************************
 * firebase
 ***********************************************************************************/
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyDc05iMpiV13cQTcxda7WjPFkc3HXEZSQ4",
    authDomain: "carselectczandriy.firebaseapp.com",
    projectId: "carselectczandriy",
    storageBucket: "carselectczandriy.appspot.com",
    messagingSenderId: "908873486884",
    appId: "1:908873486884:web:cca12b3f2e0f411fdafeb1",
    measurementId: "G-59WX50N0TT"
};

export const uiConfig = {
    signInFlow: 'popup',

    signInSuccessUrl: '/',
    // callbacks: {
    //     signInSuccessWithAuthResult: function () {
    //         setPageToLoad('ProfilePage');
    //     }
    // },

    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
};

export const fire = firebase.initializeApp(firebaseConfig);
export const db = fire.firestore();

/***********************************************************************************
 * languages
 ***********************************************************************************/
export const getTranslation = (key, dictionary, defaultDictionary) => {
    // console.log(key);
    // console.log(dictionary);
    // console.log(dictionary.dictionary[key]);
    if (!dictionary.dictionary.hasOwnProperty(key)) {
        return defaultDictionary.dictionary[key];
    }
    if (!dictionary.dictionary[key]) {
        return defaultDictionary.dictionary[key];
    }

    return dictionary.dictionary[key];
}

/*** ================================================================================
 *          Firebase
 * ================================================================================*/
export async function  getCollection (collection)    {
    return new  Promise(await function (resolve, reject) {
        fire.firestore().collection(collection).get().then(res => {
            const data = [];
            res.forEach(doc => {
                data.push({
                    idPost: doc.id,
                    ...doc.data()
                })
            });
            resolve(data)
        }).catch(err => {
            reject(err);
        });
    });
};

export function getDocInCollection(collection, id) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).doc(id)
                .get()
                .then(querySnapshot => {
                    resolve(querySnapshot.data());
                });
        } catch (e) {
            reject(e);
        }
    })
};

export function getCollectionWhereKeyValue(collection, key, value) {
    return new Promise(function (resolve, reject) {
        fire.firestore().collection(collection).where(key, "==", value).get().then(res => {
            const data = [];
            res.forEach(doc => {
                data.push({
                    idPost: doc.id,
                    ...doc.data()
                })
            });
            resolve(data)
        }).catch(err => {
            reject(err);
        });
    });
};

export function setDocumentToCollection(collection, document) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).add(document)
                .then(r => {
                    updateDocumentInCollection(collection, {...document, idPost: r.id}, r.id)
                        .then(res => console.log(res)).catch(e => console.log(e));
                    resolve({result: r});
                }).catch(e => {
                reject(e);
            })
        } catch (e) {
            reject(e);
        }
    })
};

export function updateDocumentInCollection(collection, document, idDocumnent) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).doc(idDocumnent).update(document).then(r => {
                resolve({result: r})
            }).catch(e => {
                reject(e);
            })
        } catch (e) {
            reject(e);
        }
    })
};

export function updateDocumentFromCollectionWhereKeyValue(collection, key, value, document) {
    return new Promise(function (resolve, reject) {
        try {
            getCollectionWhereKeyValue(collection, key, value).then( r1 => {
                const idDocumnent = r1[0].idPost
                fire.firestore().collection(collection).doc(idDocumnent).update(document).then(r => {
                    resolve({result: r})
                }).catch(e => {
                    reject(e);
                })
            }).catch( e => {
                reject(e);
            })
        } catch (e) {
            reject(e);
        }
    })
};

export function deleteDocumentFromCollectionWithID(collection, idPost) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).doc(idPost).delete()
                .then(result => {
                    resolve(result)
                }).catch(function (error) {
                reject(error)
            });
        } catch (e) {
            reject(e)
        }
    })
};
