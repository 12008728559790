import React, { useState, useContext } from "react";
import { FaRegHandshake } from "react-icons/fa";
import { getTranslation } from "../utils/helper";
import {AppContext} from "../appContext";
import { AiFillSafetyCertificate } from "react-icons/all";
import ModalContactUs from "../modals/ModalContactUs";


const About = () => {
    //#region Get language from context, manage languages
    const {dictionary, defaultDictionary} = useContext(AppContext);
    //#endregion

    const [connectModal, setConnectModal] = useState(false);

    return(
            <React.Fragment>
                <div className="rn-about-area ptb--40 mb--30">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img
                                            className="w-100"
                                            src="/assets/images/about/about-3.jpg"
                                            alt="About Images"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                                {getTranslation('aboutTitle', dictionary, defaultDictionary)}
                                            </h2>
                                        </div>

                                        <div className="row mt--10">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        <FaRegHandshake />
                                                    </h3>
                                                    <p>
                                                        {getTranslation('aboutBenefitOneDescription', dictionary, defaultDictionary)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        <AiFillSafetyCertificate />
                                                    </h3>
                                                    <p>
                                                        {getTranslation('aboutBenefitTwoDescription', dictionary, defaultDictionary)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section-title mt--30">
                                            <h2 className="title">
                                                {getTranslation('aboutHaveQuestionsTitle', dictionary, defaultDictionary)}
                                            </h2>
                                            <p className="description">
                                                {getTranslation('aboutHaveQuestionsDescription', dictionary, defaultDictionary)}
                                            </p>
                                            <button
                                                className="rn-button-style--2 btn-solid"
                                                type="button"
                                                name="freeconsultation"
                                                id="freeconsultation"
                                                onClick={() => setConnectModal(true)}
                                            >
                                                {getTranslation('aboutFreeConsultationButton', dictionary, defaultDictionary)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {connectModal &&
                    <ModalContactUs
                        title={getTranslation('aboutModalContactTitle', dictionary, defaultDictionary)}
                        message={''}
                        showModal={connectModal}
                        setShowModal={setConnectModal}
                    />
                }
        </React.Fragment>
   )
}
export default About
