// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Blocks Layout
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import {AppContextProvider} from "./appContext";

class Root extends Component{
  render(){
    return(
      <BrowserRouter basename={'/'}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
