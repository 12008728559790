import React from 'react';
import {Col, Row} from "react-bootstrap";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTelegram,
  FaWhatsapp
} from "react-icons/fa";

const ContactsInfo = () => {
  //#region Data for social medias
  const SocialShare = [
    {Social: <FaFacebookF/>, link: 'https://fb.com/carnice.cz'},
    {Social: <FaTelegram/>, link: 'https://t.me/carnice_cz'},
    {Social: <FaInstagram/>, link: 'https://www.instagram.com/carnice.cz/'},
    {
      Social: <FaWhatsapp/>,
      link: 'https://api.whatsapp.com/send?phone=420721030003'
    },
  ];
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <Row id={'contactsInfo'}>
        <Col md={12}>
          <ul
            className="social-share rn-lg-size d-flex justify-content-center liststyle">
            {SocialShare.map((val, i) => (
              <li key={i}>
                <a href={`${val.link}`} target={'_blank'}>
                  {val.Social}
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className={'FooterPhoneLinkContainer'}>
            <a href={'tel:+420721030003'} className={'FooterPhoneLink'}>
              +420 721 030 003
            </a>
          </p>
        </Col>
      </Row>

      <div className={'FooterStandWithUkraine'}>
        <img
          src={'/assets/images/flag-of-ukraine.svg'}
          alt={'heart'}
          className={'FooterUkraineIcon'}
        />

        <p>
          We stand with Ukraine
        </p>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default ContactsInfo;
