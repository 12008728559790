import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import HeaderCarselect from "./component/header/HeaderCarselect";
import { getCollection, setDocumentToCollection } from "./utils/helper";
import { AppContext } from "./appContext";
import LandingPage from "./home/LandingPage";
import FooterTwo from "./component/footer/FooterTwo";
import {
  czechDictionary,
  russianDictionary,
  ukrainianDictionary,
} from "./utils/models";
import {Route, Routes} from "react-router-dom";
import DetailingPage from "./home/DetailingPage";

const App = () => {
  //#region Get dictionaries from context
  const { lang, setDictionary, setDictionaries, setDefaultDictionary } =
    useContext(AppContext);

  useEffect(() => {
    getCollection("dictionaries")
      .then((dictionaries) => {
        setDictionaries(dictionaries[0].dictionaries);
        const currentDictionary = dictionaries[0].dictionaries.find(
          (dict) => dict.name === lang
        );
        setDictionary(currentDictionary);
        // console.log(dictionaries[0].dictionaries);
        setDefaultDictionary(
          dictionaries[0].dictionaries.find((dict) => dict.name === "ru")
        );
      })
      .catch((error) => console.log(error));
  }, [lang, setDefaultDictionary, setDictionaries, setDictionary]);
  //#endregion

    const [isMainPage, setIsMainPage] = useState(true);

  //#region Render
  return (
    <div className="App">
      <>
        <HeaderCarselect
            isMainPage={isMainPage}
            setIsMainPage={setIsMainPage}
        />

        <Routes>
            <Route
                index
                element={
                    <LandingPage />
                }
            />

            <Route
                path={'/detailing/*'}
                element={
                    <DetailingPage setIsMainPage={setIsMainPage}/>
                }
            />
        </Routes>

        <FooterTwo />
        {/*<button*/}
        {/*      type={'button'}*/}
        {/*      onClick={() => {setDocumentToCollection('dictionaries', {dictionaries: [ukrainianDictionary, russianDictionary, czechDictionary]})}}*/}
        {/*  >*/}
        {/*      save*/}
        {/*  </button>*/}
      </>
    </div>
  );
};

export default App;
