import React, {useState} from 'react';
import {dictionaryModel, russianDictionary, ukrainianDictionary} from "./utils/models";

export const AppContext = React.createContext({
    lang: 'ru',
    dictionary: dictionaryModel,
    dictionaries: [],
    defaultDictionary: dictionaryModel,
    setLang: () => {},
    setDictionary:  () => {},
    setDictionaries: () => {},
    setDefaultDictionary: () => {},
});

export const AppContextProvider = ({ children }) => {
    const [lang, setLang] = useState('ru');
    const [dictionary, setDictionary] = useState(dictionaryModel);
    const [defaultDictionary, setDefaultDictionary] = useState(russianDictionary);
    const [dictionaries, setDictionaries] = useState([]);

    const contextValue = {
        lang,
        setLang,
        dictionary,
        setDictionary,
        dictionaries,
        setDictionaries,
        defaultDictionary,
        setDefaultDictionary,
    }

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
};
