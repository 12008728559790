import React, {useContext, useEffect, useState} from "react";
import {FiX, FiMenu} from "react-icons/fi";
import {AppContext} from "../../appContext";
import {getTranslation} from "../../utils/helper";
import {Dropdown} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";


const HeaderCarselect = ({
                           logo,
                           color = '',
                           headerPosition = '',
                           isMainPage = true,
                           setIsMainPage = () => {
                           }
                         }) => {
  //#region Get language from context, manage languages
  const {
    lang,
    setLang,
    dictionary,
    setDictionary,
    dictionaries,
    defaultDictionary
  } = useContext(AppContext);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    // console.log('We are inside Header useEffect');
    // console.log(dictionaries);
    // console.log(lang);

    setLanguages(dictionaries.map(dict => ({
      name: dict.name,
      description: dict.description,
    })));
  }, [dictionaries]);

  const handleLanguageChange = (newLang) => {
    setLang(newLang);
    setDictionary(dictionaries.find(dict => dict.name === newLang));
  }
  //#endregion

  //#region LoadInfo
  function loadInfo() {
    console.log('All assets are loaded');
  }

  useEffect(() => {
    window.addEventListener('load', loadInfo);

    return (
      window.removeEventListener('load', loadInfo)
    );

  }, []);
  //#endregion

  //#region Menu managing
  const menuTrigger = () => {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  };

  const cLoseMenuTrigger = () => {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  };

  const elements = document.querySelectorAll('.has-droupdown > a');

  for (let i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle("active");
        this.classList.toggle("open");
      }
    }
  }
  ;
  //#endregion

  const navigate = useNavigate();

  //#region Handling logo click
  // const handleLogoClick = () => {
  //     console.log('We are inside logo click');
  //
  //     if (auth) {
  //         setPageToLoad(pagesInterface.profile);
  //     } else {
  //         setPageToLoad(pagesInterface.start);
  //     }
  // }
  //#endregion

  let logoUrl = <img src="/assets/images/logo/logo.jpg" alt="Carnice logo"
                     className={'Header_Logo'}/>;

  const getUrl = () => {
    return window.location.to;
  }

  const checkIsDetailingPage = () => {
    const url = getUrl();

    if (url.includes('detailing')) {
      return true;
    } else {
      return false;
    }
  }

  //#region Render
  return (
    <header
      className={`header-area formobile-menu ${headerPosition} ${color} header--transparent`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-3 col-6">
              <div className="header-left">
                {/*<div*/}
                {/*    className="logo"*/}
                {/*    // onClick={() => handleLogoClick()}*/}
                {/*>*/}
                {/*    <Link to="#">*/}
                {/*        {logoUrl}*/}
                {/*    </Link>*/}
                {/*</div>*/}
              </div>
            </div>

            <div className="col-lg-10 col-md-9 col-6">
              <div className="header-right justify-content-end">
                <nav className="mainmenunav d-lg-block">
                  <ul className="mainmenu">
                    {/*{auth &&*/}
                    <>
                      <li onClick={() => {
                        cLoseMenuTrigger();
                        setIsMainPage(state => !state);
                      }}>
                        {isMainPage ?
                          <Link
                            to="/detailing"
                            className={'NavLink ImportantLink'}
                          >
                            {getTranslation('headerMenuDetailing', dictionary, defaultDictionary)}
                          </Link>
                          :
                          <Link
                            to="/"
                            className={'NavLink ImportantLink'}
                          >
                            {getTranslation('headerMenuSelection', dictionary, defaultDictionary)}
                          </Link>
                        }
                      </li>
                      <li onClick={() => {
                        cLoseMenuTrigger();
                      }}>
                        <a
                          href="#prices"
                          className={'NavLink'}
                        >{getTranslation('headerMenuPrices', dictionary, defaultDictionary)}</a>
                      </li>

                      {isMainPage &&
                        <li onClick={() => {
                          cLoseMenuTrigger();
                        }}>
                          <a
                            href="#whatIsChecking"
                            className={'NavLink'}
                          >{getTranslation('headerMenuWhatIsChecking', dictionary, defaultDictionary)}</a>
                        </li>
                      }

                      <li onClick={() => {
                        cLoseMenuTrigger();
                      }}>
                        <a
                          href="#makeOrder"
                          className={'NavLink'}
                        >{getTranslation('headerMenuMakeOrder', dictionary, defaultDictionary)}</a>
                      </li>
                      {/*<li onClick={() => {*/}
                      {/*    cLoseMenuTrigger();*/}
                      {/*}}>*/}
                      {/*    <Link to="#orderCall" >{getTranslation('headerMenuOrderCall', dictionary, defaultDictionary)}</Link>*/}
                      {/*</li>*/}
                      <li onClick={() => {
                        cLoseMenuTrigger();
                      }}>
                        <a
                          href="https://www.instagram.com/carnice.cz/"
                          target={'_blank'}
                          className={'NavLink'}
                        >
                          {getTranslation('headerMenuWorkExample', dictionary, defaultDictionary)}
                        </a>
                      </li>

                      <li onClick={() => {
                        cLoseMenuTrigger();
                      }}>
                        <a
                          href="#contactsInfo"
                          className={'NavLink'}
                        >
                          {getTranslation('headerMenuContactsInfo', dictionary, defaultDictionary)}
                        </a>
                      </li>
                      {/*<li*/}
                      {/*    onClick={() => {*/}
                      {/*        cLoseMenuTrigger();*/}
                      {/*    }}*/}
                      {/*    className={'AuthLink'}*/}
                      {/*>*/}
                      {/*    <Link to="#" >*/}
                      {/*        {*/}
                      {/*            auth ? getTranslation('navBarAuthLinkLogout', dictionary, defaultDictionary)*/}
                      {/*                : getTranslation('navBarAuthLinkLogin', dictionary, defaultDictionary)*/}
                      {/*        }*/}
                      {/*    </Link>*/}
                      {/*</li>*/}
                    </>
                    {/*}*/}
                    {/*{!auth &&*/}
                    <li
                      onClick={() => {
                        // setAuthCounter(state => state + 1);
                        cLoseMenuTrigger();
                      }}
                      className={'AuthLink'}
                    >
                      <Link to="#">
                        {/*{*/}
                        {/*    auth ? getTranslation('navBarAuthLinkLogout', dictionary, defaultDictionary)*/}
                        {/*        : getTranslation('navBarAuthLinkLogin', dictionary, defaultDictionary)*/}
                        {/*}*/}
                      </Link>
                    </li>
                    {/*}*/}
                  </ul>
                </nav>

                {/*<div className={"PageSelectorContainer"}>*/}
                {/*    <Dropdown>*/}
                {/*        <Dropdown.Toggle className={'rn-btn PageSelector'} id="dropdown-basic">*/}
                {/*            {isMainPage ? getTranslation('headerMenuSelection', dictionary, defaultDictionary) :*/}
                {/*                getTranslation('headerMenuDetailing', dictionary, defaultDictionary)}*/}
                {/*        </Dropdown.Toggle>*/}

                {/*        <Dropdown.Menu>*/}
                {/*            {isMainPage ?*/}
                {/*                <Dropdown.Item*/}
                {/*                    onClick={() => {*/}
                {/*                        setIsMainPage(false);*/}
                {/*                        navigate('/detailing');*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <span>*/}
                {/*                        {getTranslation('headerMenuDetailing', dictionary, defaultDictionary)}*/}
                {/*                    </span>*/}
                {/*                </Dropdown.Item>*/}
                {/*                :*/}
                {/*                <Dropdown.Item*/}
                {/*                    onClick={() => {*/}
                {/*                        setIsMainPage(true);*/}
                {/*                        navigate('/');*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <span>*/}
                {/*                        {getTranslation('headerMenuSelection', dictionary, defaultDictionary)}*/}
                {/*                    </span>*/}
                {/*                </Dropdown.Item>*/}
                {/*            }*/}
                {/*        </Dropdown.Menu>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}

                <div className={"LanguageSelectorContainer"}>
                  <Dropdown>
                    <Dropdown.Toggle className={'rn-btn LanguageSelector'}
                                     id="dropdown-basic">
                      {dictionary.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {languages.map((language, lIndex) => (
                        <React.Fragment key={lIndex}>
                          <Dropdown.Item
                            onClick={() => handleLanguageChange(language.name)}
                          >
                                                                <span>
                                                                    {language.description}
                                                                </span>
                          </Dropdown.Item>
                        </React.Fragment>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/*<select*/}
                  {/*    className={'Field LanguageSelector'}*/}
                  {/*    defaultValue={'ru'}*/}
                  {/*    value={lang}*/}
                  {/*    onChange={(event) => handleLanguageChange(event.target.value)}*/}
                  {/*>*/}
                  {/*    {languages.map((language, lIndex) => (*/}
                  {/*        <React.Fragment key={lIndex}>*/}
                  {/*            <option*/}
                  {/*                className={'ColoredTitle'}*/}
                  {/*                value={language.name}*/}
                  {/*                // onClick={() => handleLanguageChange(language.name)}*/}
                  {/*            >*/}
                  {/*                {language.description}*/}
                  {/*            </option>*/}
                  {/*        </React.Fragment>*/}
                  {/*    ))}*/}
                  {/*</select>*/}
                </div>
                {/*<div className="header-btn">*/}
                {/*    <Link className="rn-btn AuthButton" to="#"*/}
                {/*        onClick={() => setAuthCounter(state => state + 1)}*/}
                {/*    >*/}
                {/*            <span>*/}
                {/*                {*/}
                {/*                    auth ? getTranslation('navBarAuthLinkLogout', dictionary, defaultDictionary)*/}
                {/*                        : getTranslation('navBarAuthLinkLogin', dictionary, defaultDictionary)*/}
                {/*                }*/}
                {/*            </span>*/}
                {/*    </Link>*/}
                {/*</div>*/}

                <div className="humberger-menu d-block d-lg-none pl--20">
                  <span onClick={menuTrigger}
                        className="menutrigger text-white"><FiMenu/></span>
                </div>

                <div className="close-menu d-block d-lg-none">
                  <span onClick={cLoseMenuTrigger}
                        className="closeTrigger"><FiX/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
  //#endregion
};

export default HeaderCarselect;
