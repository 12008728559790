/***********************************************************************************
 * languages
 ***********************************************************************************/
export const russianDictionary = {
  name: 'ru',
  description: 'русский',
  dictionary: {
    headerMenuWhatIsChecking: 'Что мы проверяем',
    headerMenuDetailing: 'Детейлинг',
    headerMenuSelection: 'Подбор',
    headerMenuPrices: 'Услуги и цены',
    headerMenuMakeOrder: 'Оставить заявку',
    headerMenuOrderCall: 'Заказать звонок',
    headerMenuWorkExample: 'Примеры работы',
    headerMenuContactsInfo: 'Контакты',
    aboutUsPretitle: 'Качественный автободбор',
    aboutUsTitleFirstPart: 'Желаете купить',
    aboutUsTitleSecondPart: 'проверенный',
    aboutUsTitleThirdPart: 'автомобиль в Чехии?',
    aboutUsDescription: 'Мы подберем для Вас лучшее предложение на рынке с технической и юридической гарантией',
    aboutUsButtonText: 'Связаться',
    aboutUsTitle: 'Что мы проверяем:',

    makeOrderTitle: 'Напишите',
    makeOrderDescription: 'Подберем для Вас лучший автомобиль и проведем его максимально тщательную диагностику в Праге, Брно, Карловых Варах и других городах Чехии.',
    makeOrderWishedCar: 'Марка и модель желаемого авто',
    makeOrderName: 'Как к Вам обращаться',
    makeOrderPhone: 'Ваш номер телефона',
    makeOrderAddInfo: 'Дополнительная информация',
    makeOrderButtonText: 'Оставить заявку',

    footerPreTitle: 'Свяжитесь с нами уже сегодня и получите авто своей мечты',
    footerTitle: 'Готовы поработать?',
    footerAfterTitle: 'Стоимость услуг окупается полностью или частично за счет профессионального торга наших специалистов при покупке автомобиля',
    footerContactUsButton: 'Связаться',
    footerQuickLinksTitle: 'Быстрые ссылки',
    footerMenuWhatIsChecking: 'Что проверяем',
    footerMenuPrices: 'Цены',
    footerMenuMakeOrder: 'Оставить заявку',
    footerMenuOrderCall: 'Заказать звонок',
    footerMenuWorkExample: 'Примеры работы',
    footerWriteUsTitle: 'Напишите нам',

    servicesWhatWeCheckFirstTitle: 'Оригинальность пробега',
    servicesWhatWeCheckSecondTitle: 'Выхлопная система',
    servicesWhatWeCheckThirdTitle: 'Технические жидкости',
    servicesWhatWeCheckFourthTitle: 'Остекление и оптика',
    servicesWhatWeCheckFifthTitle: 'Подвеска',
    servicesWhatWeCheckSixthTitle: 'Тормозная система',
    servicesWhatWeCheckSeventhTitle: 'Двигатель',
    servicesWhatWeCheckEighthTitle: 'Подушки безопасности',
    servicesWhatWeCheckNinthTitle: 'Коробка передач и привод',
    servicesWhatWeCheckTenthTitle: 'Кузов автомобиля',
    servicesWhatWeCheckEleventhTitle: 'Электрика и электроника',
    servicesWhatWeCheckTwelfthTitle: 'Шины и диски',
    servicesOrderCarCheckButton: 'Заказать проверку авто',

    aboutTitle: 'Стоимость наших услуг в 87% случаев является бесплатной',
    aboutBenefitOneDescription: 'Стоимость наших услуг во многих случаях окупается полностью или частично за счет профессионального торга наших специалистов с продавцом',
    aboutBenefitTwoDescription: 'Приобретая автомобиль с помощью автоподбора CarNice.cz, Вы минимизируете риски, связанные с незапланированным ремонтом, соответственно экономите свои деньги, время и нервы',
    aboutHaveQuestionsTitle: 'Остались вопросы?',
    aboutHaveQuestionsDescription: 'Закажите бесплатную консультацию наших специалистов!',
    aboutFactsTitle: 'Факты о нас',
    aboutFreeConsultationButton: 'Бесплатная консультация',
    aboutModalContactTitle: 'Бесплатная консультация',

    counterOneRequestsNumber: 'Заявок на подбор',
    counterOneCarsViewsNumber: 'Выездов на осмотр авто',
    counterOneCarsPurchasedNumber: 'Приобретенных с нашей помощью автомобилей',

    pricingTableTitle: 'Услуги и цены',
    pricingTableCardPriceFrom: 'от',
    pricingTableCardTitleOutDiagnostic: 'Выездная диагностика',
    pricingTableCardPurchaseNow: 'Заказать',
    pricingTableCardFullBodyCheck: 'Полная проверка кузова',
    pricingTableCardUnderHoodCheck: 'Проверка подкапотного пространства и технических жидкостей',
    pricingTableCardElectronicSystemCheck: 'Углубленная диагностика электронных систем',
    pricingTableCardMileageCheck: 'Оценка оригинальности пробега',
    pricingTableCardTextDrive: 'Тест-драйв',
    pricingTableCardInvestmentsEvaluation: 'Оценка необходимых вложений',
    pricingTableCardPurchaseAdvice: 'Рекомендации о целесообразности покупки',
    pricingTableCardBargain: 'Аргументированный торг',
    pricingTableCardTitleServiceStationDiagnostic: 'Диагностика на СТО',
    pricingTableCardChassisDiagnostic: 'Диагностика ходовой части на подъемнике',
    pricingTableCardRemovingAggregatesDefence: 'Снятие защиты агрегатов для детального осмотра',
    pricingTableCardCylindersEndoscopy: 'Эндоскопия цилиндров двигателя (для бензиновых)',
    pricingTableCardCompressionMeasurement: 'Замер компрессии в цилиндрах (для бензиновых)',
    pricingTableCardTitleSelectionFull: 'Подбор под ключ',
    pricingTableCardRecommendations: 'Рекомендации в выборе марок и моделей авто',
    pricingTableCardUnlimitedCheck: 'Проверка неограниченного количества автомобилей',
    pricingTableCardComplexCheck: 'Комплексная проверка автомобиля',
    pricingTableCardDealProcessing: 'Проведение сделки купли-продажи и оформления',
    pricingTableGuarantee: 'Гарантия',
    pricingTableYouGiveTechTask: 'Вы только даете техзадание и согласовываете варианты, остальное мы делаем сами',
    pricingTableCardTitleSpecialistForDay: 'Специалист на день',
    pricingTableCardCarsAgreement: 'Предварительное согласование автомобилей для осмотра',
    pricingTableCardCarsCheckDuringDay: 'Проверки выбранных Вами авто в течение дня',
    pricingTableCardChoiceOfCarForTrip: 'Поездки возможны на авто специалиста или на Вашем',
    pricingTableCardUnlimitedCheckLocation: 'Локация проверки неограниченная',
    pricingTableCardUnlimitedChecksNumber: 'Неограниченное количество проверок за рабочий день',
    pricingTableCardTitleCarDelivery: 'Доставка автомобиля',
    pricingTableCardCarDrivingByProfessional: 'Перегон Вашего авто к дому опытным водителем',
    pricingTableCardCarTesting: 'Тестирование авто в реальных условиях',
    pricingTableCardDriverResponsibility: 'Полная ответственность за действия водителя',
    pricingTableCardAllExpendituresIncluded: 'В стоимость включены абсолютно все расходы',
    pricingTableCardServicesArea: 'Услуга актуальна на всей территории стран Евросоюза',
    pricingBodyShowDetails: 'Подробнее',
    pricingBodyHideDetails: 'Свернуть',

    ourWorkTitle: 'Примеры нашей работы',
    ourWorkMoreAtInsta: 'Смотреть еще',
    serviceTwoTitle: 'Услуги подбора и диагностики авто подойдут в следующих случаях',
    serviceTwoOrderCall: 'Заказать звонок',
    serviceTwoWatchedSomeVariants: 'Вы уже посмотрели несколько вариантов и поняли, что хороший авто найти непросто',
    serviceTwoDontHaveSkills: 'Не имеете достаточно знаний в области автомобилей для самостоятельной проверки',
    serviceTwoDontHaveTime: 'У Вас нет времени на долгие и изнурительные поиски и просмотры всех авто',
    serviceTwoAfraidOfScum: 'Боитесь попасть на недобросовестного продавца и купить проблемный автомобиль',
    serviceTwoWantCheaperDeal: 'Планируете сэкономить на покупке автомобиля за счет нашего торга',
    convinceNeedOurServiceFirstParagraph: 'Самостоятельная покупка автомобиля без необходимых знаний может привести к потере финансов, нервов, и в худшем случае, здоровья.',
    convinceNeedOurServiceSecondParagraph: 'Например, автомобиль, побывавший в серьезном ДТП, второй раз уже может не спасти.',
    convinceNeedOurServiceThirdParagraph: 'Обращайтесь к специалистам',

    modalContactUsTitle: 'Бесплатная консультация',
    modalContactUsIntroduceYourselfText: 'Представьтесь',
    modalContactUsPhoneNumberText: 'Номер телефона',
    modalContactUsLeaveRequestButton: 'Оставить заявку',
    modalContactUsWeWillCallYouText: 'МЫ ПЕРЕЗВОНИМ В БЛИЖАЙШЕЕ ВРЕМЯ',

    detailingPretitle: 'Качественный детейлинг',
    detailingTitleFirstPart: 'Ваш автомобиль',
    detailingTitleSecondPart: 'засияет',
    detailingTitleThirdPart: 'как новый',
    detailingTitle: 'с любовью к автомобилям',
    detailingDescription: 'Детейлинг\n' +
      '…с любовью к автомобилям',
    detailingButtonText: 'Записаться',

    detailingServicesChapterTitle: 'Что мы можем улучшить в \n' +
      'Вашем авто',
    detailingServicesFirstTitle: 'Скрыть царапины и сколы на кузове',
    detailingServicesSecondTitle: 'Вытянуть вмятины на кузове',
    detailingServicesThirdTitle: 'Придать кузову блеск',
    detailingServicesFourthTitle: 'Нанести защитное покрытие на кузов',
    detailingServicesFifthTitle: 'Отмыть колесные диски и резину',
    detailingServicesSixthTitle: 'Очистить кузов от битума и металлической пыли',
    detailingServicesSeventhTitle: 'Удалить загрязнения салона авто',
    detailingServicesEighthTitle: 'Устранить мелкие дефекты на коже',
    detailingServicesNinthTitle: 'Очистить стекла',
    detailingServicesTenthTitle: 'Нанести гидрофобные покрытия на стекла и кузов',
    detailingServicesEleventhTitle: 'Придать салону состояние практически нового',
    detailingServicesTwelfthTitle: 'Произвести предпродажную подготовку',

    detailingContactFormTitle: 'Осуществим качественный уход за Вашим автомобилем в Праге с применением профессиональной химии и оборудования',
    detailingContactFormYourCar: 'Марка и модель Вашего авто',

    detailingPricingTableTitle: 'услуги и цены',
    'Комплекс-лайт химчистка': 'Комплекс-лайт химчистка',
    'Наружная мойка автомобиля': 'Наружная мойка автомобиля',
    'Чистка сидений и обшивок дверей при помощи профессиональной химии и оборудования': 'Чистка сидений и обшивок дверей при помощи профессиональной химии и оборудования',
    'Влажная уборка пластика': 'Влажная уборка пластика',
    'Уборка ковролина + локальная химчистка': 'Уборка ковролина + локальная химчистка',
    'Сухая уборка потолка': 'Сухая уборка потолка',
    'Уборка багажника и ниши запасного колеса + локальная химчистка': 'Уборка багажника и ниши запасного колеса + локальная химчистка',
    'Протирка стекол и зеркал': 'Протирка стекол и зеркал',
    'Комплекс-детейлинг химчистка': 'Комплекс-детейлинг химчистка',
    'Наружная мойка автомобиля с чисткой резины и дисков': 'Наружная мойка автомобиля с чисткой резины и дисков',
    'Очистка дверных проемов и петель': 'Очистка дверных проемов и петель',
    'Глубокая чистка пластика с последующей консервацией': 'Глубокая чистка пластика с последующей консервацией',
    'Глубокая чистка ковролина со снятием сидений': 'Глубокая чистка ковролина со снятием сидений',
    'Чистка потолка': 'Чистка потолка',
    'Чистка багажника и ниши запасного колеса, мойка запасного колеса': 'Чистка багажника и ниши запасного колеса, мойка запасного колеса',
    'Мойка окон и зеркал': 'Мойка окон и зеркал',
    'Уход за кожей': 'Уход за кожей',
    'Локальная чистка интерьера': 'Локальная чистка интерьера',
    'Одно сидение (500 Kč)': 'Одно сидение (500 Kč)',
    'Задние сидения (1000 Kč)': 'Задние сидения (1000 Kč)',
    'Комплект сидений -2 передних + задние (1500 Kč)': 'Комплект сидений -2 передних + задние (1500 Kč)',
    'Обшивка дверей (250 Kč)': 'Обшивка дверей (250 Kč)',
    'Мойка дверных проемов и петель (300 Kč)': 'Мойка дверных проемов и петель (300 Kč)',
    'Чистка багажника (500 Kč)': 'Чистка багажника (500 Kč)',
    'Мойка окон и зеркал (500 Kč)': 'Мойка окон и зеркал (500 Kč)',
    'Чистка потолка (1500 Kč)': 'Чистка потолка (1500 Kč)',
    'Чистка ковролина со снятием сидений (1500 Kč)': 'Чистка ковролина со снятием сидений (1500 Kč)',
    'Чистка пластика с консервацией (1500 Kč)': 'Чистка пластика с консервацией (1500 Kč)',
    'Одношаговая полировка': 'Одношаговая полировка',
    'Двухфазная мойка кузова автомобиля': 'Двухфазная мойка кузова автомобиля',
    'Удаление битумных пятен на кузове': 'Удаление битумных пятен на кузове',
    'Удаление металлических вкраплений и очистка с помощью глины': 'Удаление металлических вкраплений и очистка с помощью глины',
    'Полировка с помощью одношаговой двухкомпонентной пасты': 'Полировка с помощью одношаговой двухкомпонентной пасты',
    'Удаление остатков пасты, мойка и сушка кузова автомобиля': 'Удаление остатков пасты, мойка и сушка кузова автомобиля',
    'Двухшаговая полировка': 'Двухшаговая полировка',
    'Двухфазная мойка автомобиля': 'Двухфазная мойка автомобиля',
    'Полировка с помощью пасты высокой или средней абразивности': 'Полировка с помощью пасты высокой или средней абразивности',
    'Полировка с помощью финишной пасты': 'Полировка с помощью финишной пасты',
    'Восстановление лака': 'Восстановление лака',
    'Шлифование глубоких царапин при помощи абразива': 'Шлифование глубоких царапин при помощи абразива',
    'Полировка с помощью пасты высокой и средней абразивности': 'Полировка с помощью пасты высокой и средней абразивности',
    'Защитные покрытия и дополнительные услуги': 'Защитные покрытия и дополнительные услуги',
    'Нанесение высококачественного керамического покрытия (от 5000 Kč)': 'Нанесение высококачественного керамического покрытия (от 5000 Kč)',
    'Нанесение воскового покрытия (от 2500 Kč)': 'Нанесение воскового покрытия (от 2500 Kč)',
    'Гидрофобное кварцевое покрытие (от 1000 Kč)': 'Гидрофобное кварцевое покрытие (от 1000 Kč)',
    'Покрытие стекол "антидождь" (от 500 Kč)': 'Покрытие стекол "антидождь" (от 500 Kč)',
    'Очистка и чернение резины (от 500 Kč)': 'Очистка и чернение резины (от 500 Kč)',
    'Подкраска сколов и царапин (от 1000 Kč)': 'Подкраска сколов и царапин (от 1000 Kč)',
    'Полировка фары (500 Kč/шт)': 'Полировка фары (500 Kč/шт)',
    'Восстановление покрытия фары + защита (1500 Kč/шт)': 'Восстановление покрытия фары + защита (1500 Kč/шт)',
    'Услуги детейлинга, химчистки и полировки автомобиля актуальны в случаях': 'Услуги детейлинга, химчистки и полировки автомобиля актуальны в случаях',
    'Вы передвигаетесь в машине с детьми или перевозите домашних животных, поэтому имеются загрязнения': 'Вы передвигаетесь в машине с детьми или перевозите домашних животных, поэтому имеются загрязнения',
    'Вы устали от того, что после дождя и снега стекла грязные, загрязнения плохо отмываются от машины': 'Вы устали от того, что после дождя и снега стекла грязные, загрязнения плохо отмываются от машины',
    'Ваш кузов имеет царапины, потерял прежний блеск, видна «паутинка» на солнце': 'Ваш кузов имеет царапины, потерял прежний блеск, видна «паутинка» на солнце',
    'Хотите продать автомобиль выгодно, но переживаете, что покупателей будут смущать дефекты кузова и грязный салон': 'Хотите продать автомобиль выгодно, но переживаете, что покупателей будут смущать дефекты кузова и грязный салон',
    'Хотите защитить свой кузов от внешнего воздействия: солнца, воды, птиц, камней': 'Хотите защитить свой кузов от внешнего' +
      ' воздействия: солнца, воды, птиц, камней',
  }
};

export const ukrainianDictionary = {
  name: 'ua',
  description: 'українська',
  dictionary: {
    headerMenuWhatIsChecking: 'Що ми перевіряємо',
    headerMenuDetailing: 'Детейлінг',
    headerMenuSelection: 'Підбір',
    headerMenuPrices: 'Послуги та ціни',
    headerMenuMakeOrder: 'Залишити заявку',
    headerMenuOrderCall: 'Замовити дзвінок',
    headerMenuWorkExample: 'Приклади роботи',
    headerMenuContactsInfo: 'Контакти',
    aboutUsPretitle: 'Якісний автопідбір',
    aboutUsTitleFirstPart: 'Бажаєте купити',
    aboutUsTitleSecondPart: 'перевірений',
    aboutUsTitleThirdPart: 'автомобіль в Чехії?',
    aboutUsDescription: 'Ми підберемо для Вас найкращу пропозицію на ринку з технічною та юридичною гарантією',
    aboutUsButtonText: 'Зв\'язатися',
    aboutUsTitle: 'Що ми перевіряємо:',

    makeOrderTitle: 'Напишіть',
    makeOrderDescription: 'Підберемо для Вас найкращий автомобіль та проведемо його максимальну ретельну діагностику в Празі, Брно, Карлових Варах та інших містах Чехії',
    makeOrderWishedCar: 'Марка та модель бажаного авто',
    makeOrderName: 'Як до Вас звертатися',
    makeOrderPhone: 'Ваш номер телефону',
    makeOrderAddInfo: 'Додаткова інформація',
    makeOrderButtonText: 'Залишити заявку',

    footerPreTitle: 'Зв\'яжіться з нами вже сьогодні та отримайте авто своєї мрії',
    footerTitle: 'Готові до свівпраці?',
    footerAfterTitle: 'Вартість послуг окупається повністю або частково за рахунок професійного торгу наших спеціалістів при купівлі автомобіля',
    footerContactUsButton: 'Зв\'язатися',
    footerQuickLinksTitle: 'Швидкі посилання',
    footerMenuWhatIsChecking: 'Що перевіряємо?',
    footerMenuPrices: 'Ціни',
    footerMenuMakeOrder: 'Залишити заявку',
    footerMenuOrderCall: 'Замовити дзвінок',
    footerMenuWorkExample: 'Приклади роботи',
    footerWriteUsTitle: 'Напишіть нам',

    servicesWhatWeCheckFirstTitle: 'Оригінальність пробігу',
    servicesWhatWeCheckSecondTitle: 'Вихлопна система',
    servicesWhatWeCheckThirdTitle: 'Технічні рідини',
    servicesWhatWeCheckFourthTitle: 'Скло та оптика',
    servicesWhatWeCheckFifthTitle: 'Підвіска',
    servicesWhatWeCheckSixthTitle: 'Гальмівна система',
    servicesWhatWeCheckSeventhTitle: 'Двигун',
    servicesWhatWeCheckEighthTitle: 'Подушки безпеки',
    servicesWhatWeCheckNinthTitle: 'Коробка передач та привід',
    servicesWhatWeCheckTenthTitle: 'Кузов автомобіля',
    servicesWhatWeCheckEleventhTitle: 'Електрика та електроніка',
    servicesWhatWeCheckTwelfthTitle: 'Шини і диски',
    servicesOrderCarCheckButton: 'Замовити перевірку авто',

    aboutTitle: 'Вартість наших послуг в 87% випадків є безкоштовною',
    aboutBenefitOneDescription: 'Вартість наших послуг в багатьох випадках окупається повністю або частково за рахунок професійного торгу наших спеціалістів з продавцем',
    aboutBenefitTwoDescription: 'Купуючи автомобіль за допомогою автопідбору CarNice.cz Ви мінімізуєте ризики, пов\'язані з незапланованим ремонтом, відповідно економите свої гроші, час та нерви',
    aboutHaveQuestionsTitle: 'Залишилися питання?',
    aboutHaveQuestionsDescription: 'Замовте безкоштовну консультацію наших спеціалістів!',
    aboutFactsTitle: 'Факти про нас',
    aboutFreeConsultationButton: 'Безкоштовна консультація',
    aboutModalContactTitle: 'Безкоштовна консультація',

    counterOneRequestsNumber: 'Заявок на підбір',
    counterOneCarsViewsNumber: 'Виїздів на огляд авто',
    counterOneCarsPurchasedNumber: 'Придбаних з нашою допомогою автомобілей',

    pricingTableTitle: 'Послуги та ціни',
    pricingTableCardPriceFrom: 'від',
    pricingTableCardTitleOutDiagnostic: 'Виїзна діагностика',
    pricingTableCardPurchaseNow: 'Замовити',
    pricingTableCardFullBodyCheck: 'Повна перевірка кузова',
    pricingTableCardUnderHoodCheck: 'Перевірка підкапотного простору і технічних рідин',
    pricingTableCardElectronicSystemCheck: 'Поглиблена діагностика електронних систем',
    pricingTableCardMileageCheck: 'Оцінка оригінальності пробігу',
    pricingTableCardTextDrive: 'Тест-драйв',
    pricingTableCardInvestmentsEvaluation: 'Оцінка необходніх вкладень',
    pricingTableCardPurchaseAdvice: 'Рекомендації щодо доцільності придбання',
    pricingTableCardBargain: 'Аргументований торг',
    pricingTableCardTitleServiceStationDiagnostic: 'Діагностика на СТО',
    pricingTableCardChassisDiagnostic: 'Діагностика ходової частини на під\'йомнику',
    pricingTableCardRemovingAggregatesDefence: 'Зняття захисту агрегатів для детального огляду',
    pricingTableCardCylindersEndoscopy: 'Ендоскопія циліндрів двигуна (для бензинових)',
    pricingTableCardCompressionMeasurement: 'Замір компресії в циліндрах (для бензинових)',
    pricingTableCardTitleSelectionFull: 'Підбір під ключ (all inclusive)',
    pricingTableCardRecommendations: 'Рекомендації щодо вибору марок та моделей авто',
    pricingTableCardUnlimitedCheck: 'Перевірка необмеженої кількості автомобілей',
    pricingTableCardComplexCheck: 'Комплексна перевірка автомобіля',
    pricingTableCardDealProcessing: 'Проведення угоди купівлі-продажу та оформлення',
    pricingTableGuarantee: 'Гарантія',
    pricingTableYouGiveTechTask: 'Ви тільки даєте техзавдання та узгоджуєте варіанти, все інше ми робимо самі',
    pricingTableCardTitleSpecialistForDay: 'Спеціаліст на день',
    pricingTableCardCarsAgreement: 'Попереднє узгодження автомобілей для огляду',
    pricingTableCardCarsCheckDuringDay: 'Перевірка обраних Вами авто протягом дня',
    pricingTableCardChoiceOfCarForTrip: 'Поїздки можливі на авто спеціаліста або на Вашому',
    pricingTableCardUnlimitedCheckLocation: 'Локація перевірки необмежена',
    pricingTableCardUnlimitedChecksNumber: 'Необмежена кількість перевірок за робочий день',
    pricingTableCardTitleCarDelivery: 'Доставка автомобіля',
    pricingTableCardCarDrivingByProfessional: 'Перегін Вашого авто до будинку досвідченим водієм',
    pricingTableCardCarTesting: 'Тестування авто в реальних умовах',
    pricingTableCardDriverResponsibility: 'Повна відповідальність за дії водія',
    pricingTableCardAllExpendituresIncluded: 'У вартість включено абсолютно всі витрати',
    pricingTableCardServicesArea: 'Послуга актуальна на всій території країн Євросоюзу',
    pricingBodyShowDetails: 'Детальніше',
    pricingBodyHideDetails: 'Згорнути',

    ourWorkTitle: 'Приклади нашої роботи',
    ourWorkMoreAtInsta: 'Дивитися ще',
    serviceTwoTitle: 'Послуги підбору та діагностики авто підійдуть в наступних випадках',
    serviceTwoOrderCall: 'Замовити дзвінок',
    serviceTwoWatchedSomeVariants: 'Ви вже подивилися декілька варіантів і зрозуміли, що хороше авто знайти нелегко',
    serviceTwoDontHaveSkills: 'Не маєте достатньо знань щодо автомобілів для самостійної перевірки',
    serviceTwoDontHaveTime: 'У Вас нема часу на довгі та виснажливі пошуки та перегляди всіх авто',
    serviceTwoAfraidOfScum: 'Боїтесь натрапити на недобросовісного продавця і придбати проблемний автомобіль',
    serviceTwoWantCheaperDeal: 'Плануєте зекономити на придбанні за рахунок нашого торгу',
    convinceNeedOurServiceFirstParagraph: 'Самостійна купівля автомобіля без необхідних знань може призвести до втрати грошей, нервів і, в найгіршому випадку, здоров\'я',
    convinceNeedOurServiceSecondParagraph: 'Наприклад, автомобіль, що побував в серйозній ДТП, другий раз вже може не врятувати.',
    convinceNeedOurServiceThirdParagraph: 'Звертайтеся до спеціалістів',

    modalContactUsTitle: 'Безкоштовна консультація',
    modalContactUsIntroduceYourselfText: 'Відрекомендуйтеся',
    modalContactUsPhoneNumberText: 'Номер телефону',
    modalContactUsLeaveRequestButton: 'Залиште заявку',
    modalContactUsWeWillCallYouText: 'МИ ПЕРЕДЗВОНИМО НАЙБЛИЖЧИМ ЧАСОМ',


    detailingPretitle: 'Якісний детейлінг',
    detailingTitleFirstPart: 'Ваш автомобіль',
    detailingTitleSecondPart: 'засяє',
    detailingTitleThirdPart: 'як новий',
    detailingDescription: 'Детейлінг\n' +
      '…з любов\'ю до автомобілей',
    detailingButtonText: 'Записатися',


    detailingServicesChapterTitle: 'Що ми можемо покращити у\n' +
      'Вашому авто',
    detailingServicesFirstTitle: 'Приховати подряпини і сколи на кузові',
    detailingServicesSecondTitle: 'Витягнути вм\'ятини на кузові',
    detailingServicesThirdTitle: 'Надати кузову блиск',
    detailingServicesFourthTitle: 'Нанести захисне покриття на кузов',
    detailingServicesFifthTitle: 'Відмити колісні диски та гуму',
    detailingServicesSixthTitle: 'Очистити кузов від бітума та металевого пилу',
    detailingServicesSeventhTitle: 'Видалити забруднення салону авто',
    detailingServicesEighthTitle: 'Ліквідувати дрібні дефекти на шкірі',
    detailingServicesNinthTitle: 'Очистити скло',
    detailingServicesTenthTitle: 'Нанести гідрофобні покриття на скло та кузов',
    detailingServicesEleventhTitle: 'Надати салону стану практично нового',
    detailingServicesTwelfthTitle: 'Здійснити передпродажну підготовку',

    detailingContactFormTitle: 'Здійснимо якісний догляд за Вашим автомобілем в Празі із застосуванням професійної хімії та обладнання',
    detailingContactFormYourCar: 'Марка і модель Вашого авто',

    detailingPricingTableTitle: 'Послуги та ціни',
    'Комплекс-лайт химчистка': 'Комплекс-лайт хімчистка',
    'Наружная мойка автомобиля': 'Зовнішня мийка автомобіля',
    'Чистка сидений и обшивок дверей при помощи профессиональной химии и оборудования': 'Чистка сидінь та обшивок дверей за допомогою професійної хімії та обладнання',
    'Влажная уборка пластика': 'Вологе прибирання пластику',
    'Уборка ковролина + локальная химчистка': 'Прибирання ковроліну + локальна хімчистка',
    'Сухая уборка потолка': 'Сухе прибирання стелі',
    'Уборка багажника и ниши запасного колеса + локальная химчистка': 'Прибирання багажника і ніші запасного колеса + локальна хімчистка',
    'Протирка стекол и зеркал': 'Протирання скла та дзеркал',
    'Комплекс-детейлинг химчистка': 'Комплекс-детейлінг хімчистка',
    'Наружная мойка автомобиля с чисткой резины и дисков': 'Зовнішня мийка автомобіля з чисткою гуми та дисків',
    'Очистка дверных проемов и петель': 'Очищення двірних отворів та петель',
    'Глубокая чистка пластика с последующей консервацией': 'Глибоке очищення пластику з наступною консервацією',
    'Глубокая чистка ковролина со снятием сидений': 'Глибоке очищення ковроліну зі зняттям сидінь',
    'Чистка потолка': 'Очищення стелі',
    'Чистка багажника и ниши запасного колеса, мойка запасного колеса': 'Очищення багажника та ніші запасного колеса, мойка запасного колеса',
    'Мойка окон и зеркал': 'Мійка вікон і дзеркал',
    'Уход за кожей': 'Догляд за шкірою',
    'Локальная чистка интерьера': 'Локальне очищення интер\'єра',
    'Одно сидение (500 Kč)': 'Одне сидіння (500 Kč)',
    'Задние сидения (1000 Kč)': 'Задні сидіння (1000 Kč)',
    'Комплект сидений -2 передних + задние (1500 Kč)': 'Комплект сидінь - 2 передніх + задні (1500 Kč)',
    'Обшивка дверей (250 Kč)': 'Обшивка дверей (250 Kč)',
    'Мойка дверных проемов и петель (300 Kč)': 'Мийка дверних отворів і петель (300 Kč)',
    'Чистка багажника (500 Kč)': 'Очищення багажника (500 Kč)',
    'Мойка окон и зеркал (500 Kč)': 'Мийка вікон і дзеркал (500 Kč)',
    'Чистка потолка (1500 Kč)': 'Очищення стелі (1500 Kč)',
    'Чистка ковролина со снятием сидений (1500 Kč)': 'Очищення ковроліну зі зняттям сидінь (1500 Kč)',
    'Чистка пластика с консервацией (1500 Kč)': 'Очищення пластику з консервацією (1500 Kč)',
    'Одношаговая полировка': 'Однокрокове полірування',
    'Двухфазная мойка кузова автомобиля': 'Двофазна мийка кузова автомобіля',
    'Удаление битумных пятен на кузове': 'Видалення бітумних плям на кузові',
    'Удаление металлических вкраплений и очистка с помощью глины': 'Видалення металевих вкраплень та очищення за допомогою глини',
    'Полировка с помощью одношаговой двухкомпонентной пасты': 'Полірування з використанням однокрокової двокомпонентної пасти',
    'Удаление остатков пасты, мойка и сушка кузова автомобиля': 'Видалення залишків пасти, мийка та сушка кузова автомобіля',
    'Двухшаговая полировка': 'Двокрокове полірування',
    'Двухфазная мойка автомобиля': 'Двофазна мийка автомобіля',
    'Полировка с помощью пасты высокой или средней абразивности': 'Полірування з використання пасти вискокої або середньої абразивності',
    'Полировка с помощью финишной пасты': 'Полирірування з використанням фінішної пасти',
    'Восстановление лака': 'Відновлення лаку',
    'Шлифование глубоких царапин при помощи абразива': 'Шліфування глибоких подряпин з використанням абразиву',
    'Полировка с помощью пасты высокой и средней абразивности': 'Полірування з використанням пасти високої та середньої абразивності',
    'Защитные покрытия и дополнительные услуги': 'Захисні покриття і додаткові послуги',
    'Нанесение высококачественного керамического покрытия (от 5000 Kč)': 'Нанесення високоякісного керамічного покриття (від 5000 Kč)',
    'Нанесение воскового покрытия (от 2500 Kč)': 'Нанесення воскового покриття (від 2500 Kč)',
    'Гидрофобное кварцевое покрытие (от 1000 Kč)': 'Гідрофобне кварцеве покриття (від 1000 Kč)',
    'Покрытие стекол "антидождь" (от 500 Kč)': 'Покриття скла "антидощ" (від 500 Kč)',
    'Очистка и чернение резины (от 500 Kč)': 'Очищення і черніння гуми (від 500 Kč)',
    'Подкраска сколов и царапин (от 1000 Kč)': 'Підфарбування сколів і подряпин (від 1000 Kč)',
    'Полировка фары (500 Kč/шт)': 'Полирування фари (500 Kč/шт)',
    'Восстановление покрытия фары + защита (1500 Kč/шт)': 'Відновлення покриття фари + захист (1500 Kč/шт)',
    'Услуги детейлинга, химчистки и полировки автомобиля актуальны в случаях': 'Послуги детейлінга, хімчистки і полірування автомобіля актуальні у випадках',
    'Вы передвигаетесь в машине с детьми или перевозите домашних животных, поэтому имеются загрязнения': 'Ви пересуваєтесь в машині з дітьми або перевозите домашніх тварин, тому наявні забруднення',
    'Вы устали от того, что после дождя и снега стекла грязные, загрязнения плохо отмываются от машины': 'Ви втомилися від того, що після дощу та снігу вікна брудні, забруднення погано відмиваються від машини',
    'Ваш кузов имеет царапины, потерял прежний блеск, видна «паутинка» на солнце': 'Ваш кузов має подряпини, втратив колишній блиск, видно «павутиння» на сонці',
    'Хотите продать автомобиль выгодно, но переживаете, что покупателей будут смущать дефекты кузова и грязный салон': 'Хочете продати автомобіль вигідно, але хвилюєтесь, що покупців будуть бентежити дефекти кузова і брудний салон',
  }
};

export const czechDictionary = {
  name: 'cz',
  description: 'čeština',
  dictionary: {
    headerMenuWhatIsChecking: 'Co kontrolujeme',
    headerMenuPrices: 'Služby a ceny',
    headerMenuMakeOrder: 'Vytvořit objednávku',
    headerMenuOrderCall: 'Objednat hovor',
    headerMenuWorkExample: 'Příklady práce',
    headerMenuContactsInfo: 'Контакты',
    aboutUsPretitle: 'Kvalitní služby',
    aboutUsTitleFirstPart: 'Chtěli byste koupit',
    aboutUsTitleSecondPart: 'zkontrolované auto',
    aboutUsTitleThirdPart: 'auto v České republice?',
    aboutUsDescription: 'Vybereme pro vás tu nejlepší variantu na trhu',
    aboutUsButtonText: 'Kontakt',
    aboutUsTitle: 'Co kontrolujeme',
    makeOrderTitle: 'Napište nám',
    makeOrderDescription: 'Vybereme pro vás nejlepší vůz a provedeme jeho nejdůkladnější diagnostiku v Praze, Brně, Karlových Varech a dalších městech ČR.',
    makeOrderWishedCar: 'Značka a model požadovaného vozu/auta',
    makeOrderName: 'Jak vás kontaktovat',
    makeOrderPhone: 'Vaše telefonní číslo',
    makeOrderAddInfo: 'Další informace',
    makeOrderButtonText: 'Odeslat objednávku',
    footerPreTitle: 'Kontaktujte nás ještě dnes a získejte své vysněné auto',
    footerTitle: 'Jste připraveni skoušet?',
    footerAfterTitle: 'Náklady na služby jsou plně nebo částečně hrazeny profesionálním vyjednáváním našich specialistů při koupi vozu',
    footerContactUsButton: 'Kontakt',
    footerQuickLinksTitle: 'Rychlé odkazy',
    footerMenuWhatIsChecking: 'Co kontrolujeme',
    footerMenuPrices: 'Ceny',
    footerMenuMakeOrder: 'Vytvořit objednávku',
    footerMenuOrderCall: 'Objednat hovor',
    footerMenuWorkExample: 'Příklady práce',
    footerWriteUsTitle: 'Napište nám',
    servicesWhatWeCheckFirstTitle: 'Originalita najetých kilometrů',
    servicesWhatWeCheckSecondTitle: 'Výfukový systém',
    servicesWhatWeCheckThirdTitle: 'Technické kapaliny',
    servicesWhatWeCheckFourthTitle: 'Zasklení/zasklívání a optika',
    servicesWhatWeCheckFifthTitle: 'Přívěsek',
    servicesWhatWeCheckSixthTitle: 'Brzdový systém',
    servicesWhatWeCheckSeventhTitle: 'Motor',
    servicesWhatWeCheckEighthTitle: 'Airbagy',
    servicesWhatWeCheckNinthTitle: 'Převodovka a pohon',
    servicesWhatWeCheckTenthTitle: 'Karoserie',
    servicesWhatWeCheckEleventhTitle: 'Electrical a electronika',
    servicesWhatWeCheckTwelfthTitle: 'Pneumatiky a kola',
    servicesOrderCarCheckButton: 'Objednat kontrolu auta',

    aboutTitle: 'Naše služby jsou zdarma ve 87 % připadech',
    aboutBenefitOneDescription: 'Náklady na naše služby se v mnoha případech zcela nebo částečně vyplatí díky profesionálnímu vyjednávání našich specialistů s prodejcem',
    aboutBenefitTwoDescription: 'Zakoupením vozu/auta pomocí automatického výběru CarNice.cz minimalizujete rizika spojená s neplánovanými opravami a šetříte tak své peníze, čas i nervy',
    aboutHaveQuestionsTitle: 'Máte otázky?',
    aboutHaveQuestionsDescription: 'Zarezervujte si bezplatnou konzultaci s našimi odborníky/specialisty!',
    aboutFactsTitle: 'Fakta o nás',
    aboutFreeConsultationButton: 'Konzultace zdarma',
    aboutModalContactTitle: 'Bezplatná konzultace',
    counterOneRequestsNumber: 'Požadavky na vyhledávání',
    counterOneCarsViewsNumber: 'Prohlídky aut',
    counterOneCarsPurchasedNumber: 'Auta koupená s naší pomocí',

    pricingTableTitle: 'Služby a ceny',
    pricingTableCardPriceFrom: 'od',
    pricingTableCardTitleOutDiagnostic: 'Diagnostika na místě',
    pricingTableCardPurchaseNow: 'Objednat',
    pricingTableCardFullBodyCheck: 'Kontrola celého karosérii',
    pricingTableCardUnderHoodCheck: 'Kontrola motorového prostoru a technických kapalin',
    pricingTableCardElectronicSystemCheck: 'Hloubková diagnostika elektronických systémů',
    pricingTableCardMileageCheck: 'Kontrola originality najetých kilometrů',
    pricingTableCardTextDrive: 'Test Drive',
    pricingTableCardInvestmentsEvaluation: 'Vyhodnocení nezbytných investic',
    pricingTableCardPurchaseAdvice: 'Purchase Advice',
    pricingTableCardBargain: 'Špatná nabídka',
    pricingTableCardTitleServiceStationDiagnostic: 'Diagnostika stanice',
    pricingTableCardChassisDiagnostic: 'Diagnostika podvozku na zdvihu',
    pricingTableCardRemovingAggregatesDefence: 'Odstranění ochrany agregátů pro podrobnou kontrolu',
    pricingTableCardCylindersEndoscopy: 'Endoskopie válců motoru (pro benzín)',
    pricingTableCardCompressionMeasurement: 'Měření komprese válce (pro benzín)',
    pricingTableCardTitleSelectionFull: 'Výběr na klíč',
    pricingTableCardRecommendations: 'Doporučení pro značky a modely automobilů',
    pricingTableCardUnlimitedCheck: 'Kontrola neomezeného počtů aut',
    pricingTableCardComplexCheck: 'Komplexní kontrola aut',
    pricingTableCardDealProcessing: 'Provedení nákupu a prodeje a zpracování',
    pricingTableGuarantee: 'Záruka',
    pricingTableYouGiveTechTask: 'Vy poskytnete pouze podmínky a dohodnete se na možnostech, zbytek uděláme sami/my',

    pricingTableCardTitleSpecialistForDay: 'Specialista na den',
    pricingTableCardCarsAgreement: 'Předběžné schválení auta ke kontrole',
    pricingTableCardCarsCheckDuringDay: 'Kontroly vámi vybraných aut během dne',
    pricingTableCardChoiceOfCarForTrip: 'Cesta jsou k dispozici s vozem specialisty nebo vaším',
    pricingTableCardUnlimitedCheckLocation: 'Jakákoliv lokace pro kontrolu',
    pricingTableCardUnlimitedChecksNumber: 'Neomezený počet kontrol za pracovní den',
    pricingTableCardTitleCarDelivery: 'Dodávka auta',
    pricingTableCardCarDrivingByProfessional: 'Dopravíme auto přímo k vám domů zkušeným řidičem',
    pricingTableCardCarTesting: 'Testovaní auta v reálných',
    pricingTableCardDriverResponsibility: 'Plná odpovědnost za jednání/činnost řidiče',
    pricingTableCardAllExpendituresIncluded: 'V ceně jsou absolutně všechny výdaje',
    pricingTableCardServicesArea: 'Služba je platná ve všech zemích EU',
    pricingBodyShowDetails: 'Detaily',
    pricingBodyHideDetails: 'Skrýt',

    ourWorkTitle: 'Příklady naší práce',
    ourWorkMoreAtInsta: 'Zobrazit více',
    serviceTwoTitle: 'Výběr vozu a diagnostické služby jsou vhodné v následujících případech',
    serviceTwoOrderCall: 'Objednat hovor',
    serviceTwoWatchedSomeVariants: 'Už jste se podívali na několik možností a zjistili jste, že není snadné najít dobré auto',
    serviceTwoDontHaveSkills: 'Nemáte dostatek znalostí o autech, abyste se otestovali',
    serviceTwoDontHaveTime: 'Nemáte čas na dlouhé a vyčerpávající hledání a prohlížení všech aut',
    serviceTwoAfraidOfScum: 'Bojíte se dostat k bezohlednému prodejci a koupit si problematické auto',
    serviceTwoWantCheaperDeal: 'Plánujete ušetřit peníze na nákup vozu prostřednictvím naší dohodování o ceně ',
    convinceNeedOurServiceFirstParagraph: 'Koupě auta bez potřebných odborných znalostí může přivést ke stresu, ztrátě finančních prostředků, dokonce i zdraví.',
    convinceNeedOurServiceSecondParagraph: 'Například, vůz, který měl vážnou nehodu, má mnohem nižší úroveň bezpečnosti',
    convinceNeedOurServiceThirdParagraph: 'Kontaktujte odborníky',

    modalContactUsTitle: 'Bezplatná konzultace/ konzultace zdarma',
    modalContactUsIntroduceYourselfText: 'Představte se',
    modalContactUsPhoneNumberText: 'Telefonní číslo',
    modalContactUsLeaveRequestButton: 'Požadavek opustit',
    modalContactUsWeWillCallYouText: 'BRZY VÁM ZAVOLÁME',
  }
}

export const dictionaryModel = {
  name: '',
  description: '',
  dictionary: {},
};
